import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image";

const SalesQuestion = ({
    name,
    email,
    color,
    noPadding = false,
    isGrey = false,
    isLightGrey = false,
    locale,
}) => {
    const data = useStaticQuery(graphql`
    query SalesQuestion {
        allContentfulPersonnel(filter: {node_locale: {eq: "en-US"}}) {
            edges {
                node {
                    telephone
                    title
                    email
                    portrait {
                        gatsbyImageData(height: 350, width: 350, placeholder: BLURRED)
                        title
                    }
                }
            }
        }
    }
  `);

    const filter = data.allContentfulPersonnel.edges.find((f) => f.node.email === email);
    const image = filter.node.portrait;
    const tel = filter.node.telephone;
    const title = filter.node.title;

    const isLocaleDe = () => locale === 'de';

    const getTranslation = (key) => {
        if (key === 'haveQuestions') {
            return isLocaleDe() ? 'Haben Sie Fragen?' : 'Have questions?';
        }
        if (key === 'reachOutTo') {
            return isLocaleDe() ? 'Kontaktieren Sie' : 'Reach out to';
        }
        if (key === 'our') {
            return isLocaleDe() ? 'unser' : 'our';
        }
        return key;
    };

    return (
        <section className={`section is-medium ${isGrey ? " bg-grey-1" : ""} ${isLightGrey ? " bg-grey" : ""} ${noPadding ? " no-padding" : ""}`}>
            <div className="container content">
                <div className="columns is-hidden-mobile">
                    <div className="column has-text-centered">
                        <img src={getSrc(image)} alt={name} width="350" height="350" loading="lazy" className="black-and-white" />
                    </div>
                    <div className="column" style={{ alignSelf: "center" }}>
                        <h1 className={`title size-40 is-${color} margin-0`}>
                            {getTranslation('haveQuestions')}
                        </h1>
                        <br />
                        <div className="padding-bottom-20">
                            <div style={{ display: "inline-flex" }}>
                                <h2 className="subtitle size-24 no-margin">
                                    {getTranslation('reachOutTo')}
                                </h2>
                                <div className={`is-${color}-arrow-with-spacing`}>
                                    <div className="line"></div>
                                    <div className="point"></div>
                                </div>
                                <h2 className="subtitle size-24 no-margin">
                                    {name},
                                </h2>
                            </div>
                            <br />
                            <h2 className="subtitle size-24 no-margin">
                                {getTranslation('our')} {title}.
                            </h2>
                        </div>

                        <a href={`mailto:${email}`}>
                            <span className={`size-24 is-${color}`}>{email}</span><br />
                        </a>
                        {tel && (
                            <a href={`tel:${tel}`}>
                                <span className={`size-24 is-${color}`}>{tel}</span>
                            </a>
                        )}
                    </div>
                </div>
                <div className="columns is-mobile has-text-centered is-hidden-tablet is-multiline">
                    <div className="column is-12">
                        <h1 className={`title size-40 is-${color}`}>
                            {getTranslation('haveQuestions')}
                        </h1>
                        <br />
                        <img src={getSrc(image)} alt={name} width="350" height="350" loading="lazy" className="black-and-white" />
                    </div>
                    <div className="column is-12" style={{ alignSelf: "center" }}>
                        <div className="padding-bottom-20">
                            <div style={{ display: "inline-flex" }}>
                                <h2 className="subtitle size-24 no-margin">
                                    {getTranslation('reachOutTo')}
                                </h2>
                                <div className={`is-${color}-arrow-with-spacing`}>
                                    <div className="line"></div>
                                    <div className="point"></div>
                                </div>
                                <h2 className="subtitle size-24 no-margin">
                                    {name},
                                </h2>
                            </div>
                            <br />
                            <h2 className="subtitle size-24 no-margin">
                                {getTranslation('our')} {title}.
                            </h2>
                        </div>
                        <a href={`mailto:${email}`}>
                            <span className={`size-20 is-${color}`}>{email}</span><br />
                        </a>
                        {tel && (
                            <a href={`tel:${tel}`}>
                                <span className={`size-20 is-${color}`}>{tel}</span>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

SalesQuestion.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    locale: PropTypes.string,
}


export default SalesQuestion;
