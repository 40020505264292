import React from "react";
import HubspotForm from "../components/HubspotForm";

import Layout from "../components/layout/Layout";
import SalesQuestion from "../components/SalesQuestion";
import placeholder_cross from "../assets/images/placeholder_cross.svg";
import { navigate } from "gatsby";
import { pushSAEvent, pushToGTM, postFBEvent } from "../helpers";

export default ({ location }) => {
    pushSAEvent("visit_newsletter");
    const handleSubmit = () => {
        //postToTGM("google_ads_newsletter_form_submit", "Newsletter Form Submit");
        pushToGTM({
            'event': 'frends_form_submit',
            'form_type': 'Newsletter Form Submit',
            'resource_name': 'Newsletter'
        })
        if (typeof window !== "undefined"){
            if (window.fbq != null){
                window.fbq('track', 'CompleteRegistration')
            }
        }

        pushSAEvent("submit_newsletter");
        postFBEvent("Subscribe", "Newsletter Page")

        navigate("/thanks-for-the-registration/")
    }
    return (
        <Layout title="Newsletter" transparentNavigation={true} location={location}>
            <main id="content" style={{ marginTop: "-80px" }}>
                <section className="hero is-medium is-purple-brand-bg">
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1 className="title font-bold size-100 is-transparent text-stroke-white">
                                subscribe to our newsletter
                            </h1>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-three-fifths is-offset-one-fifth has-text-centered trial-form">
                                <HubspotForm
                                    formId="68a187dd-b436-4bef-8c31-b77a082ce924"
                                    loading={<div>Loading...</div>}
                                    onSubmit={handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <SalesQuestion
                    color="green"
                    email="juha@frends.com"
                    name="Juha Moisio"
                    image={placeholder_cross}
                />
            </main>
        </Layout>
    )
}